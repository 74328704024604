;(function(){
	π.mods.push(function () {
		console.log("π loaded.")

		initStickyHeader();
		initAnchorScrolling();
		// initMobileMenu();
		initExhibits();
		initCenterImages();
		//initScrollInAndOutOfFixed();
		initParallax();
		initBackgroundVideos();
		initCenterizedImagesAndVideo();

		initCircleGraphs();
		initCountUps();


		if(π1('.filterBox')) initDirectoryFilters();
		if(π1('#backToTop')) initBackToTopButton();
	})

	function initParallax() {
		var parallax = document.querySelectorAll(".parallax"),
			speed = 0.5;
		window.onscroll = function(){
			[].slice.call(parallax).forEach(function(el,i){
				var windowYOffset = window.pageYOffset,
					elBackgrounPos = "50% " + (windowYOffset * speed) + "px";
				el.style.backgroundPosition = elBackgrounPos;
			});
		};
	}

	////////////////////////////////////////
	// BACKGROUND VIDEOS
	////////////////////////////////////////
	function initBackgroundVideos() {
		$('.videoBG video').each(function() {
			var vid = $(this);
			window.requestAnimationFrame(function() {
				if(vid.get(0).readyState === 4 || !vid.hasClass('on')) {
					vid.addClass('on');
					vid.get(0).play();
				}
			});
		});
	}
	function initCenterizedImagesAndVideo() {
		$('.centerBox').each(function(i) {
			var box = $(this);
			window.requestAnimationFrame(function() {
				centerizeThisThing(box);
			});
		});
	}

	function initStickyHeader() {
		var header = π1('header');
		if (!header) return
		
		setInterval(function() {
			if(window.scrollY >= 200 && !header.hasClass('sticky')) {
				header.addClass('sticky');
			}
			else if(window.scrollY < 200 && header.hasClass('sticky')) {
				header.killClass('sticky');
			}
		}, 10);
	}

	function initMobileMenu() {
		var button = π1('#menuButton');
		if (!button) return
		
		button.onclick = function() {
			(πbody.hasClass('openMenu')) ? πbody.killClass('openMenu'): πbody.addClass('openMenu');
		};
	}

	function initBackToTopButton() {
		var backButton = π1('#backToTop');
		setInterval(function() {
			if(window.scrollY >= 200 && !backButton.hasClass('show')) {
				backButton.addClass('show');
			}
			else if(window.scrollY < 200 && backButton.hasClass('show')) {
				backButton.killClass('show');
			}
		}, 10);
	}


	////////////////////////////////////
	// EXHIBIT SECTION ANIMATIONS
	////////////////////////////////////
	function initExhibits() {
		if(π('.exhibit')) {

			setTimeout(function() {
				π('.exhibit').forEach(function (el) {
					window.requestAnimationFrame(function () {
						toggleExhibits(el);
					});
				});
			}, 1000);

			console.log('fn: exhibits');
		}
	}
	function toggleExhibits(el) {

		var margin = (el.dataset.rangeMargin) ? parseInt(el.dataset.rangeMargin): 70;
		var inRange = elementInRange(el, margin);

		if(inRange && !el.hasClass('inRange')) {
			el.addClass('inRange');
			if(el.dataset.rangeFunction) processRangeFunction(el);
		}
		else if(!inRange && el.hasClass('inRange') && !el.hasClass('exhibitOnOnly')) el.killClass('inRange');

		window.requestAnimationFrame(function() {
			toggleExhibits(el);
		});
	}
	// CUSTOM FUNCTIONALITY
	function processRangeFunction(el) {
		switch(el.dataset.rangeFunction) {
			case 'switchProductSection':
				switchProductSection(el);
				break;
		}
	}

	function switchProductSection(el) {

		// PROGRESS NAV
		var id = el.id;
		var navs = $('#progressButtons a');
		var thisNav = $('a[rel=' + id + ']');
		var maxItems = navs.length-1;
		var idx = navs.index(thisNav);

		if(!thisNav.hasClass('selected')) {
			navs.removeClass('selected');
			thisNav.addClass('selected');
			for(var i=0; i < navs.length; i++) {
				(i < idx) ? navs.eq(i).addClass('prev'): navs.eq(i).removeClass('prev');
			}
		}

		// PROGRESSBAR
		var pBar = $('#progressBar');
		var perc = (100/maxItems)*idx;

		if(!pBar.hasClass('done')) pBar.height(perc+'%');
		if(perc == 100 && !pBar.hasClass('done')) pBar.addClass('done');
		if(maxItems == idx && !$('#purpleBar').hasClass('finish')) $('#purpleBar').addClass('finish');

		// CTA'S
		if(id == 'processor' && !$('#needHelp').hasClass('on')) $('#needHelp').addClass('on');

		(id !== 'introduction' && id !== 'submit') ? navs.parent().addClass('on'): navs.parent().removeClass('on');

		if(id == 'submit') $('#personalInfoFields input[name="your-name"]').focus();
	}


	////////////////////////////////////
	// SCROLL TO/FROM FIXED CENTER
	////////////////////////////////////
	function initCenterImages() {
		π('*[data-scroll-center]').forEach(function(el, i) {
			scrollInAndOutOfFixedCenter(el, i);
		});
	}

	function scrollInAndOutOfFixedCenter(el, i) {

		var parent = π1('#'+el.dataset.scrollCenter);
		var pPos = scrollPosition($(parent));
		var maxTop = ((window.innerHeight/2)-(el.offsetHeight/2));
		var maxBottom = maxTop + el.offsetHeight;

		var isTop = (pPos.top > maxTop);
		var isBottom = (pPos.bottom < maxBottom);
		var isCenter = (pPos.top < maxTop && pPos.bottom > maxBottom);

		// ADD CLASSES
		if(isTop && !el.hasClass('fixToTop')) el.addClass('fixToTop');
		if(isBottom && !el.hasClass('fixToBottom')) el.addClass('fixToBottom');
		if(isCenter && !el.hasClass('fixToCenter')) el.addClass('fixToCenter');

		if(!isTop && el.hasClass('fixToTop')) el.killClass('fixToTop');
		if(!isBottom && el.hasClass('fixToBottom')) el.killClass('fixToBottom');
		if(!isCenter && el.hasClass('fixToCenter')) el.killClass('fixToCenter');

		requestAnimationFrame(function() {
			scrollInAndOutOfFixedCenter(el, i);
		});
	}




	////////////////////////////////////
	// SCROLL TO/FROM FIXED BOTTOM
	////////////////////////////////////
	function initScrollInAndOutOfFixed() {
		π('*[data-scroll-to-fixed]').forEach(function(el, i) {
			var pos = el.dataset.scrollToFixed;
			var parent = el.dataset.scrollToFixedParent;
			scrollInAndOutOfFixedPosition(el, i, pos, parent);
		});
	}

	function scrollInAndOutOfFixedPosition(el, i, pos, parent) {


		var parentPos = scrollPosition($(parent));
		p = π1(parent);
		var pTop = parentPos.top;
		var pBottom = parentPos.bottom;
		var topInView = (pTop >= 0);
		var bottomInView = (pBottom - window.innerHeight < 0);

		console.log(topInView+", "+bottomInView);
		
		
		// var maxTop = ((window.innerHeight/2)-(el.offsetHeight/2));
		// var maxBottom = maxTop + el.offsetHeight;



		var isTop = (topInView);
		var isBottom = (parentPos.bottom < maxBottom);
		var isCenter = (parentPos.top < maxTop && parentPos.bottom > maxBottom);

		// ADD CLASSES
		if(isTop && !el.hasClass('fixToTop')) el.addClass('fixToTop');
		if(isBottom && !el.hasClass('fixToBottom')) el.addClass('fixToBottom');

		if(isCenter) {
			if(pos == 'top' && !el.hasClass('fixToTop')) el.addClass('fixToTop');
			if(pos == 'center' && !el.hasClass('fixToCenter')) el.addClass('fixToCenter');
			if(pos == 'bottom' && !el.hasClass('fixToBottom')) el.addClass('fixToBottom');
		}


		// KILL CLASSES
		if(!isTop && el.hasClass('fixToTop')) el.killClass('fixToTop');
		if(!isBottom && el.hasClass('fixToBottom')) el.killClass('fixToBottom');
		if(!isCenter && el.hasClass('fixToCenter')) el.killClass('fixToCenter');
		if(isCenter) {
			if(pos != 'top' && el.hasClass('fixToTop')) el.killClass('fixToTop');
			if(pos != 'center' && el.hasClass('fixToCenter')) el.killClass('fixToCenter');
			if(pos != 'bottom' && el.hasClass('fixToBottom')) el.killClass('fixToBottom');
		}

		requestAnimationFrame(function() {
			scrollInAndOutOfFixedPosition(el, i, pos, parent);
		});
	}



	/////////////////////////////////////////
	// HASHTAG/ANCHOR LINK ANIMATED SCROLLING
	/////////////////////////////////////////
	function initAnchorScrolling() {
		if (typeof $ == "undefined") {
			console.log("jquery is needed for anchor scrolling.")
			return
		} 
		
		$('a[href*="#"]').each(function() {
			var url = $(this).attr('href').replace(/\/$/, "");
			var name = (url.indexOf("#") !== -1) ? url.substring(url.indexOf("#")+1): url.match(/([^\/]*)\/*$/)[1];
			var exists = (typeof($("a[name='"+name+"']").offset()) !== "undefined" || typeof($("#"+name).offset()) !== "undefined");

			if(exists) {
				var theTarget = (typeof($("a[name='"+name+"']").offset()) !== "undefined") ? $("a[name='"+name+"']"): $("#"+name);
				$(this).click(function(e) {
					e.preventDefault();
					scrollToAnchor(theTarget);
				});
			}
		});
	}






////////////////////////////////////////
// COUNT-UPS
// DEPENDENCIES: http://inorganik.github.io/countUp.js/
////////////////////////////////////////
	function initCountUps() {
		if(π('.countup').length) {
			var countUps = π('.countup');
			if (countUps) {
				countUps.forEach(function (el, i) {

					el.setAttribute("data-countup-value", el.textContent.replace(',',''));
					el.innerHTML = '<span class="spacer">' + el.textContent + '</span><span class="number">0</span>';

					window.requestAnimationFrame(function () {
						isCounterInRange(el);
					});
				});
			}

			console.log('fn: count ups');
		}
	}
	function isCounterInRange(el) {

		if(elementInRange(el, 80)) {

			var speed = 2;
			var value = el.getAttribute('data-countup-value');
			var number = el.π1('.number');
			var decPlaces = decimalPlaces(value);

			var easingFn = function (t, b, c, d) {
				var ts = (t /= d) * t;
				var tc = ts * t;
				return b + c * (tc + -3 * ts + 3 * t);
			};
			var options = {
				useEasing : true,
				easingFn: easingFn,
				useGrouping : true,
				separator : ',',
				decimal : '.',
				prefix : '',
				suffix : ''
			};
			var demo = new CountUp(number, 0, value, decPlaces, speed, options);
			demo.start();
		}
		else {
			window.requestAnimationFrame(function () {
				isCounterInRange(el);
			});
		}
	}
	function decimalPlaces(num) {
		var match = (''+num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
		if (!match) { return 0; }
		return Math.max(0, (match[1] ? match[1].length : 0) - (match[2] ? +match[2] : 0));
	}



	////////////////////////////////////
	// CIRCLE GRAPHS
	////////////////////////////////////
	function initCircleGraphs() {
		if(π('.circle-graph').length) {
			var graphs = π('.circle-graph');
			if (graphs) {
				graphs.forEach(function (el, i) {

					var content = el.innerHTML;
					var newContent = '<svg class="theCircle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 133 133"><circle class="circle" cx="66.5" cy="66.5" r="65"/></svg>';
					newContent += '<div class="text">'+content+'</div>';

					el.innerHTML = newContent;
					el.addClass('circleGraph');
					el.killClass('circle-graph');

					window.requestAnimationFrame(function () {
						isGraphInRange(el);
					});
				});
			}

			console.log('fn: circle graphs');
		}
	}
	function isGraphInRange(el) {

		if(elementInRange(el, 80)) {

			var num = parseInt(el.π1('.countup .spacer').innerHTML)*4;
			console.log(parseInt(el.π1('.countup .spacer').innerHTML)*4);
			el.π1('svg').css('stroke-dasharray', num+' '+(400-num));

		}
		else {
			window.requestAnimationFrame(function () {
				isGraphInRange(el);
			});
		}
	}



	////////////////////////////////////
	// UTILITIES
	////////////////////////////////////
	function elementOverlappingAnother(box, win) {
		box = $(box);
		win = $(win);

		var boxPos = scrollPosition(box);
		var winPos = scrollPosition(win);

		return (boxPos.bottom > winPos.top && boxPos.top < winPos.bottom);
	}

	function elementInView(box) {
		box = $(box);
		var boxPos = scrollPosition(box);
		return (boxPos.bottom > 0 && boxPos.top < $(window).height())
	}

	function elementInRange(box, margin) {

		box = $(box);
		margin = (!margin) ? 0.2: margin*0.01;

		var boxPos = scrollPosition(box);
		var winHeight = $(window).height();

		var zoneBottom = parseInt(winHeight*margin);
		var zoneTop = parseInt(winHeight*(1-margin));

		return (boxPos.bottom > zoneTop && boxPos.top < zoneBottom);

	}

	function scrollPosition(el) {
		var boxTop = el.offset().top-$(window).scrollTop();
		var boxBottom = boxTop+el.outerHeight();
		return {
			top: boxTop,
			bottom: boxBottom
		}
	}

	function scrollToAnchor(el, speed, padding) {

		el = $(el);
		speed = (speed) ? speed: 500;
		padding = (padding) ? padding: -1;
		var theTop = (el.hasClass('scrollToCenter')) ? el.offset().top - ((window.innerHeight/2)-(el.height()/2)): el.offset().top-padding;
		
		$('html, body').stop().animate({ scrollTop:theTop }, speed, 'easeInOutCubic', function() {
			//window.location.hash = name;
		});
	}

	// function centerizeThisThing(el, parent) {
	// 	parent = (!parent) ? el.parent(): parent;
	// 	var th = parent.height(), tw = parent.width(), ih = el.height(), iw = el.width();
	// 	var theHeight = ((th/tw) > (ih/iw)) ? th: 'auto';
	// 	var theWidth = ((th/tw) > (ih/iw)) ? 'auto': tw;
	//
	// 	image.css({ height: theHeight, width: theWidth });
	//
	// 	window.requestAnimationFrame(function() {
	// 		centerizeThisThing(el, parent);
	// 	});
	// }

	function centerizeThisThing(box) {

		//if(box.is(':visible')) {
		var parent = (box.hasClass('toWindow')) ? $(window) : box.parent();

		var imgW = box.width();
		var imgH = box.height();
		var winW = parent.width();
		var winH = parent.height();
		var theH, theW, theT, theL;

		//REDUCE IMAGE SIZES TO ASPECT RATIO
		var commonDivisor = gcd(imgW, imgH);
		var ratioW = imgW / commonDivisor, ratioH = imgH / commonDivisor;

		var theR = (winH * ratioW) / winW;
		var isWide = false, isLandscape = false, isPortrait = false;

		if (theR < ratioH) isWide = true;
		else if (theR > ratioH && theR < ratioW) isLandscape = true;
		else if (theR > ratioW) isPortrait = true;

		theH = (isPortrait || isLandscape) ? winH : 'auto';
		theW = (isWide) ? winW : 'auto';
		theL = (isPortrait || isLandscape) ? -((imgW - winW) / 2) : 0;
		theT = (isWide) ? -((imgH - winH) / 2) : 0;
		box.css({'width': theW, 'height': theH, 'margin-left': theL, 'margin-top': theT});
		//}



		window.requestAnimationFrame(function() {
			centerizeThisThing(box);
		});

	}


	// DETERMINE GREATEST COMMON DIVISOR OF 2 NUMBERS IN RATIO. USED TO DETERMINE ASPECT RATIO OF IMAGES
	function gcd(x, y) {
		while (y !== 0) {
			var z = x % y;
			x = y;
			y = z;
		}
		return x;
	}


})()